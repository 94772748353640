import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://srt.ajncloud.shop/api';
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState('en');
  const [taskId, setTaskId] = useState(localStorage.getItem('task_id') || null);
  const [status, setStatus] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [serverStatus, setServerStatus] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert('Загрузи файл');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', language);

    try {
      const response = await axios.post(`${backendUrl}/upload-audio/`, formData);
      const newTaskId = response.data.task_id;
      setTaskId(newTaskId);
      localStorage.setItem('task_id', newTaskId);
      setStatus('В процессе...');
      checkStatus(newTaskId);
      setFile(null);
    } catch (error) {
      console.error('Ошибка загрузки файла:', error);
    }
  };

  const checkStatus = useCallback((taskId) => {
    const interval = setInterval(async () => {
      try {
        const response = await axios.get(`${backendUrl}/result/${taskId}`);
        if (response.data.status === 'Титры созданы') {
          setStatus('Титры созданы');
          setDownloadUrl(`${backendUrl}/download/${response.data.download_url.split('/').pop()}`);
          clearInterval(interval);
        } else {
          setStatus(response.data.status);
        }
      } catch (error) {
        console.error('Ошибка статуса процесса:', error);
      }
    }, 10000);
  }, [backendUrl]);

  useEffect(() => {
    if (taskId) {
      checkStatus(taskId);
    }
  }, [taskId, checkStatus]);

  const checkServerStatus = useCallback(() => {
    axios
      .get(`${backendUrl}/status`)
      .then(() => setServerStatus('online'))
      .catch(() => setServerStatus('offline'));
  }, [backendUrl]);

  useEffect(() => {
    checkServerStatus();
    const interval = setInterval(() => {
      checkServerStatus();
    }, 60000);
    return () => clearInterval(interval);
  }, [checkServerStatus]);

  return (
    <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="card p-4" style={{ width: '500px' }}>
        <h1 className="text-center mb-4">Субтитры из mp3</h1>
        
        {serverStatus === 'online' ? (
          <div className="alert alert-success" role="alert">
            Сервер онлайн
          </div>
        ) : serverStatus === 'offline' ? (
          <div className="alert alert-danger" role="alert">
            Сервер оффлайн
          </div>
        ) : (
          <div className="alert alert-warning" role="alert">
            Проверка состояния сервера...
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Выбери аудио файл:</label>
            <input type="file" className="form-control" onChange={handleFileChange} />
          </div>
          <div className="form-group mt-3">
            <label>Выбери язык аудио:</label>
            <select className="form-control" value={language} onChange={handleLanguageChange}>
              <option value="en">Английский</option>
              <option value="ar">Арабский</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary btn-block mt-4">Отправить</button>
        </form>

        {taskId && <p className="mt-4">ID процесса: {taskId}</p>}
        {status && <p className="mt-2">Статус: {status}</p>}
        {downloadUrl && (
          <div className="mt-3">
            <a
              href={downloadUrl}
              className="btn btn-success"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setTaskId(null);
                localStorage.removeItem('task_id');
                setDownloadUrl('');
                setStatus('');
              }}
            >
              Скачать SRT файл
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
